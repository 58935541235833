.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.PensieriHeader {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
}

.HeaderTitle {
  height: 100%;
  display: flex;
  align-items: center;
}
.HeaderActions {
  display: flex;
  padding-top: 3px;
  padding-bottom: 3px;
}

.boomer-emoji {
  font-size: 20px;
}

.emoji-head-action {
  margin-right: 5px;
  border-radius: 3px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-self: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 20px;
}

.HeaderActions .emoji-head-action:last-child {
  margin-right: 0;
}

.PensieriHeader h1 {
  color: white;
  margin: 0;
}

h1 span.title {
  font-style: italic;
}

.Pensieri {
  flex: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PensieroBox {
  color: white;
  height: 100%;
  position: relative;
}

.PrevArea {
  position: absolute;
  z-index: 23;
  left: 0;
  right: 50%;
  top: 0;
  bottom: 0;
}
.NextArea {
  position: absolute;
  z-index: 23;
  left: 50%;
  right: 0;
  top: 0;
  bottom: 0;
}

.PensieroBox img.boomer-image {
  transition: filter 1s ease-in;
  height: 100%;
  min-width: 200px;
  max-width: 100%;
  object-fit: cover;
}
img.boomer-image.loading {
  filter: blur(3px);
}

.PensieroBox p {
  margin: 0;
  left: 5px;
  right: 5px;
  font-style: italic;
  border-radius: 4px;
  padding: 2px;
  color: cornsilk;
  background: rgba(0, 0, 0, 0.55);
  font-size: 30px;
  bottom: 5px;
  position: absolute;
}
